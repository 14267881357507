import {getSessionLoginData,getUsersbyids} from "./Common"
// import {useDispatch} from 'react-redux';
const getUserNames = (users, array) => {
    let names = [];
    for (let i of users) {
        if (array.indexOf(i.id) > -1) {
            names.push(i.fullname);
        }
    }
}
function openCallWindow(conversation_id) {
    var ww = window.screen.availWidth * 0.8;
    var hh = window.screen.availHeight * 0.8;
    var left = (window.screen.width / 2) - (ww / 2);
    var top = (window.screen.height / 2) - (hh / 2);
    // window.name = "parent";
    var win_voip = window.open("", 'calling_'+conversation_id, "width=" + ww + ",height=" + hh + ', top=' + top + ', left=' + left);
    console.log('win_voip',win_voip.location.href)
    if (conversation_id && win_voip && win_voip.location){
        if(win_voip.location.href === 'about:blank') win_voip.location.href = '/call/' + conversation_id;
        return win_voip;
    } 
    // clean_timer_user();
}
function closeRingWindow(ring_index,dispatch,props){
    // debugger
    clearTimeout(window.timer1); console.log('call:timer:clear:',window.timer1);
    let ring_audio = document.getElementById('ring_audio_'+ring_index);
    if(ring_audio) ring_audio.pause();
    
    dispatch({ type: 'set_callringpopup', payload: {status: false} });
    dispatch({type:'busy_call_msg',payload:false}) ;
    props.setPopup({type:'voipHoldMute', data: false});
    props.setPopup({type:'mergeconvpopup', data: false});
}
// function showVersionPopup(restart_time,props){
//     if(restart_time){
//         window.server_ts = restart_time;     
//     }else{
//         window.server_ts = 0; // 2
//     }
//     // console.log(`xmpp:ver_update:server:${window.server_ts}`);

//     window.app_ts = localStorage.getItem('app_ts'); 
//     // console.log(`xmpp:ver_update:react:${window.app_ts}`);
//     // debugger
//     if(window.app_ts === undefined || window.app_ts === null || window.app_ts === 'undefined' || window.app_ts === 'null'){ 
//         // localStorage.setItem('app_ts',window.server_ts); 
//         props.updateVersion('yes');
//         console.log(`xmpp:ver_update:popup:${window.app_ts}`);
//     }else{
//         window.diff_ts = (parseInt(window.server_ts) - parseInt(window.app_ts))/1000;

//         // console.log(`xmpp:ver_update:diff:${window.diff_ts}`);
//         if(window.diff_ts > 0){
//             props.updateVersion('yes');
//             console.log(`xmpp:ver_update:popup:${window.app_ts}`);
//         }

//     }

    

// }

function showVersionPopup(restart_time,props){
    console.log('restart_time::',restart_time);
    
    if(restart_time) window.server_ts = restart_time;
    else window.server_ts = 0;

    if( parseInt(localStorage.getItem('app_ts')) ){
        window.app_ts = parseInt(localStorage.getItem('app_ts'));
    }else{
        window.app_ts = new Date(localStorage.getItem('app_ts')).valueOf() 
    }
    //
    
    if(window.app_ts === undefined || window.app_ts === null || window.app_ts === 'undefined' || window.app_ts === 'null'){ 
        localStorage.setItem('app_ts',window.server_ts); 
        props.updateVersion('yes');
        console.log(`xmpp:ver_update:popup:${window.app_ts}`);
    }else{
        window.diff_ts = (parseInt(window.server_ts) - parseInt(window.app_ts));
        if(window.diff_ts > 0){
            props.updateVersion('yes');
            window.app_ts = window.server_ts;
            localStorage.setItem('app_ts',window.server_ts);
            console.log('restart_time::up::',restart_time, window.diff_ts);
        }
    }
}

// function openCallWindow(conversation_id, win_name = 'calling') {
//     var ww = window.screen.availWidth * 0.8;
//     var hh = window.screen.availHeight * 0.8;
//     var left = (window.screen.width / 2) - (ww / 2);
//     var top = (window.screen.height / 2) - (hh / 2);
//     // window.name = "parent";
//     var call_url = new URL('/call/' + conversation_id,window.location.origin);
//     console.log('openCallWindow:', call_url);
//     // debugger
//     var win_voip = window.open(call_url, 'calling_'+conversation_id, "width=" + ww + ",height=" + hh + ', top=' + top + ', left=' + left);
//     // console.log('openCallWindow:', win_voip);
//     // debugger
//     // console.log('openCallWindow:', win_voip,win_voip.location,win_voip.location.href);
    
//     if (conversation_id && win_voip && win_voip.location){
//         if(win_voip.location.href === 'about:blank'){
//           win_voip.location.href = '/call/' + conversation_id;  
//         } 
       
//     } 
//      return win_voip;
    

//     // clean_timer_user();
// }
const getparticipants_details =  (user_ids = [])=>{
    console.log()
    if(user_ids == null){
        return [];
    }
    let data = [];
    let logindata = getSessionLoginData();
    // console.log(94,logindata)
    let all_users = logindata.all_users;
    // console.log(99,all_users)
    if(all_users !== null && all_users !== undefined){
       for(let i of all_users){
        if(user_ids.indexOf(i.id) > -1){
            data.push({
                "id": i.id,
                "email": i.email,
                "firstname": i.firstname,
                "lastname": i.lastname,
                "is_active": i.is_active,
                "img": i.img,
                "role": i.role,
                "fnln":i.fnln,
                "createdat": i.createdat,
                "eod_report": i.eod_report
            })
        }
            

       }
    }
    // console.log(118,data)
    return data;
    
}
const getparticipants_redux =  (user_ids = [], logindata)=>{
    // debugger;
    if(user_ids == null){
        return [];
    }
    let data = [];
    // let logindata = getSessionLoginData();
    // console.log(94,logindata)
    let all_users = logindata.all_users;
    console.log('all_users:redux',all_users)
    // console.log(99,all_users)
    if(all_users !== null && all_users !== undefined){
       for(let i of all_users){
        if(user_ids.indexOf(i.id) > -1){
            data.push({
                "id": i.id,
                "email": i.email,
                "firstname": i.firstname,
                "lastname": i.lastname,
                "is_active": i.is_active,
                "img": i.img,
                "role": i.role,
                "fnln":i.fnln,
                "createdat": i.createdat
            })
        }
            

       }
    }
    // console.log(118,data)
    return data;
    

}
// const getparticipants_details_idb =  (user_ids = [])=>{
//     return new Promise(async (resolve)=>{
//         let data = [];
//         let all_users = await getUsersbyids(user_ids);
//         console.log(122,all_users)
//         if(all_users !== null && all_users !== undefined){
//         for(let i of all_users){
//             if(user_ids.indexOf(i.id) > -1){
//                 data.push({
//                     "id": i.id,
//                     "email": i.email,
//                     "firstname": i.firstname,
//                     "lastname": i.lastname,
//                     "is_active": i.is_active,
//                     "img": i.img,
//                     "role": i.role,
//                     "fnln":i.fnln,
//                     "createdat": i.createdat
//                 })
//             }
                

//         }
//         }
//         resolve(data);

//     })
    
    
    

// }
// const getparticipants_name_idb = (user_ids = [])=>{
//     let data = [];
//     let logindata = getSessionLoginData();
//     // console.log(94,logindata)
//     let all_users = logindata.all_users;
//     if(all_users !== null && all_users !== undefined){
//        for(let i of all_users){
//         if(user_ids.indexOf(i.id) > -1){
//             data.push(i.firstname + ''+ i.lastname)
//         }
            

//        }
//     }
//     return data;
    

// }
const getparticipants_name = (user_ids = [])=>{
    let data = [];
    let logindata = getSessionLoginData();
    // console.log(94,logindata)
    let all_users = logindata.all_users;
    if(all_users !== null && all_users !== undefined){
       for(let i of all_users){
        if(user_ids.indexOf(i.id) > -1){
            data.push(i.firstname +' '+ i.lastname)
        }
            

       }
    }
    return data;
    

}


const taskPermission = (task,user_for,p_type,return_type = 'true')=>{
    let assignee = ['create_checklist', 'edit_notes', 'due_time', 'due_time', 'track_hour', 'track_cost', 'keywords', 'flagged', 'status', 'progress', 'review', 'task_start_date', 'task_due_date','drag_drop','observer_update'];
    let observers = ['discussion','notification','create_checklist','edit_notes']
    let returndata = true;
    let class_list = true;
    let owned_by = task?.owned_by || [];
    if(task.created_by !== user_for && !owned_by.includes(user_for) ){
        if(task.assign_to && task.assign_to.indexOf(user_for) > -1){
            if(assignee.indexOf(p_type) === -1){
                returndata = false;
                class_list = "pointer_event_false_disabled"
            }
        }else if(task.observers && task.observers.indexOf(user_for) > -1){
            if(observers.indexOf(p_type) === -1){
                returndata = false;
                class_list = "pointer_event_false_disabled"
            }
        }else{
            returndata = false;
            class_list = "pointer_event_false_disabled"
        }

    }
    if(return_type === 'true'){
        // console.log('taskPermission',task.task_title,returndata);
        return returndata;
    }else{
        return class_list;
    }
    
    // console.log(task)
}

export {
    getUserNames,
    openCallWindow,
    showVersionPopup,
    closeRingWindow,
    getparticipants_details,
    getparticipants_name,
    taskPermission,
    getparticipants_redux
    // getparticipants_details_idb,
    // getparticipants_name_idb
}